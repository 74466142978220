<template>
<section class="content">
  <div class="card card-info">
    <div class="card-header">
          <div class="box-tools card-tools toolbar">
            <router-link to="/artikel/create" class="btn btn-primary btn-flat"
              ><i class="fa fa-plus"></i> Tambah Artikel
            </router-link>
          </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-hover" ref="tblartikel">
              <thead>
                <tr>
                  <th>KATEGORI</th>
                  <th>CABANG</th>
                  <th>JUDUL</th>
                  <th>TANGGAL TERBIT</th>
                  <th>DITERBITKAN SAMPAI</th>
                  <th>STATUS</th>
                  <th>COVER</th>
                  <th>TINDAKAN</th>
                </tr>
              </thead>
              <tbody @click="handleClick"></tbody>
        </table>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import { authFetch, createTable } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import { VueEditor } from "vue2-editor";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import moment from 'moment';

export default {
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log('load initial data', this.$route);
  },
  components: { 
    VueEditor,
    vSelect, 
  },
  data: () => ({
    roles: '',
    form: {
      content: "<h1>Tulis konten di sini..</h1>",
      mapel_id: "",
      title: "",
    },
    method: "POST",
    customToolbar: [
      [{ font: [] }],
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" }
      ],
      [{ header: 1 }, { header: 2 }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ color: [] }, { background: [] }],
      ["link", "image", "video"],
      [{ direction: "rtl" }],
      // ["clean"]
    ],
  }),
  methods: {
    handleClick(e) {
      // const evt = this.$refs;
      if (e.target.closest("button")) {
        let id = e.target.dataset.id;
          if (e.target.dataset.action == "view") {
              this.$router.push("/preview-artikel/" + e.target.dataset.id);
          } else if (e.target.dataset.action == "edit") {
            this.$router.push({ path: `/artikel/edit/${id}`});
          } else if (e.target.dataset.action == "hapus") {
              Swal.fire({
              title: "Hapus artikel?",
              icon: "question",
              denyButtonText: '<i class="fa fa-times"></i> Hapus',
              showCancelButton: true,
              showDenyButton: true,
              showConfirmButton: false,
            }).then((result) => {
              if (result.isDenied) {
                authFetch("/akademik/artikel/" + id, {
                  method: "DELETE",
                  body: "id=" + id,
                })
                  .then((res) => {
                    return res.json();
                  })
                  .then((js) => {
                    this.table.api().ajax.reload();
                  });
              }
            });
          }
      }
    },
  },
  mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblartikel, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/akademik/artikel",
      frame: false,
      filter: true,
      selectedRowClass: "",
      serverSide: true,
      // scrollX: true,
      columns: [
        { data: "kategori" },
        { data: "cabang" },
        { data: "title" },
        { data: "published_on",
          render: function (data, type, row, meta) {
          return moment(data).format('DD/MM/YYYY')
          }, },
        { data: "published_until",
          render: function (data, type, row, meta) {
          return moment(data).format('DD/MM/YYYY')
          }, },
        { data: "active",
          render: function (data, type, row, meta) {
            if (data < 1) {
              return '<span class="badge badge-primary">DRAFT</span>';
            } else if (data == 1) {
              return '<span class="badge badge-success">ACTIVE</span>';
            } else if (data > 1) {
              return '<span class="badge badge-secondary">NOT ACTIVE</span>';
            }
          }, },
        { data: "img_cover",
          width: "40px",
          sortable: false,
          render: function (data, type, row, meta) {
            let img = '<img width="50px;" src="' +
            window.$apiUrl + "/file/image/" + data + '" v-if="todo.file_path">';
            if (row.file_path) {
              return img;
            } else {
              return '-';
            }
          }, },
        { data: "action",
          sortable: false,
          render: function (data, type, row, meta) {
              let dropdown =
            `<div class="btn-group">
            <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Klik
            </button>
            <div class="dropdown-menu dropdown-menu-right">
            <button type="button" class="btn btn-sm btn-warning dropdown-item" data-action="edit" data-id="` +
                    row.id +
                    `"> Edit </button>
            <button type="button" class="btn btn-sm btn-danger dropdown-item" data-action="hapus" data-id="` +
                    row.id +
                    `">Hapus</button>
            </div>
            </div>`;

            // <button type="button" class="btn btn-sm btn-info dropdown-item" data-action="view" data-id="` +
            //         row.id +
            //         `"> Preview </button>

            return dropdown;
          }, },
      ],
      filterBy: [0, 1, 2],
      rowCallback: function (row, data) {},
    });
  },
};
</script>